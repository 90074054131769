import './App.css';
import RestarauntPoll from './components/RestarauntPoll'

function App() {
  return (
    <div className="App">
      <header className="Plan Buddy">
      <RestarauntPoll/>
      </header>
    </div>
  );
}

export default App;
